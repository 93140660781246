import React from "react"

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import { Helmet } from "react-helmet";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


import WachbueroLogo from '../images/Logo.svg';

import firebase from "gatsby-plugin-firebase"

let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  fontSize: '1.25rem'
};



export default function Home() {

  React.useEffect(() => {
    firebase.analytics().logEvent('page_not_found');
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Helmet defer={false}>
          <title>Wachbüro | Ooops ...</title>
          <link rel="canonical" href="http://www.wachbuero.app" />
        </Helmet>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }} spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h1" style={{color: "#CE2721", fontWeight: "bold"}}>Oooops .... </Typography>
            <Typography variant="body1">Die gesuchte Seite ist leider nicht auffindbar.</Typography>
            <Button style={{backgroundColor: "#CE2721", color:"white", marginTop: 40}} variant="contained" onClick={() => window.location.href='www.wachbuero.app'}>Zurück zur Startseite</Button>
          </Grid>
        </Grid>
        <Grid container wrap="wrap-reverse">
          <Grid item xs={12}>
            <Typography variant="caption">© Copyright 2020 <b>Wachbüro App</b>.<br />All rights reserved.</Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
